import './App.scss';
import partOne from './lgp1.png';
import partTwo from './lgp2.png';
import Mist from './mist.png';
import React from 'react';

class LoadScreen extends React.Component {
    render() {
      return (
          
          <div className="bdpink">
              <div>
          <div className="myLogoWrapper">
              

<div>
<img className="Spin" src={partOne}></img>
</div>
<div>
<img className="Static" src={partTwo}></img>
</div>
<div>
<img className="Mist" src={Mist}></img>
</div>
          </div>
          </div>
          </div>

      );
    }
  }

  export default LoadScreen