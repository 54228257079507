import './App.scss';
import partOne from './lgp1.png';
import partTwo from './lgp2.png';
import Mist from './mist.png';
import React from 'react';
import Vid from './vidheroc.mp4';
import Logo from './cplogo.png';
import Cart from './bag.png';

import Bur from './HamMenu.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  BrowserRouter
} from "react-router-dom";
import About from "./About";

import Shop from "./Shop";
import Contact from "./Contact";


class Home extends React.Component {


  
    render() {

    
      return (
          

<main className="HomeTR">
   
   <div>
       <video src={Vid} autoPlay="true" loop="true" />
   </div>
   <header className="viewport-header">
 
 <div className="navBar">
   
     <img className="cpl" src={Logo}/>
     
     
 </div>
 
 


     </header>

     <div className="mainText">
       <h1>Explore <span>Mist</span></h1>
       <button class="button" ><a className="shopLink" href="https://www.mist-shop.tn">
	Découvrez Nos Produits
	<div class="button__horizontal"></div>
	<div class="button__vertical"></div>
  </a></button>
     </div>
   <Bur />
  
  
        
    
</main>
          

      );
    }
  }

  export default Home