import React, { useEffect, useState } from "react";
import LoadScreen from './LoadScreen'
import Home from './Home';
import App from './App';


function Loader() {
  const [data, setData] = useState([]);
  const [done, setDone] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      fetch("https://jsonplaceholder.typicode.com/posts")
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setData(json);
          setDone(true);
        });
    }, 2000);
  }, []);

  return (
    <>
      {!done ? (
        <LoadScreen />
      ) : (
        <App />
      )}
    </>
  );
}

export default Loader;