import React from "react";
import Logo from './cplogo.png';
import Bur from './HamMenu.js';


class Contact extends React.Component {
    render() {
      return (
          

        <main className="HomeTR">
           
          
           <header className="viewport-header">
         
         <div className="navBar">
           
             <img className="cpl" src={Logo}/>
             
             
         </div>
         
         
        
        
             </header>
        
             <div className="mainTextA">
               <h1>Contactez <span>Nous</span></h1>
               <p className="textab"><strong>Support Commercial</strong> : contact@mist-shop.tn</p>
               <p className="textab"><strong>Signalez un bug</strong> : sysadmin@mist-shop.tn</p>
               
          
             </div>
           <Bur />
          
          
                
            
        </main>
                  
        
              );
    }
  }

  export default Contact