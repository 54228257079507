import React from "react";
import './App.scss';
import partOne from './lgp1.png';
import partTwo from './lgp2.png';
import Mist from './mist.png';

import Vid from './vidheroc.mp4';
import Logo from './cplogo.png';
import Caart from './bag.png';

import Bur from './HamMenu.js';
import Products from "./Products";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  BrowserRouter
} from "react-router-dom";
import About from "./About";


import Contact from "./Contact";
import Cart from "./CartMenu"
import Slider from "react-slick";



class Shop extends React.Component {
    render() {
        return (
            
  
  <main className="HomeTR">
     
    
     <header className="viewport-header">
   
   <div className="navBar">
     
       <img className="cpl" src={Logo}/>


       
       
   </div>
   <div>
     
   </div>
   
   
  
  
       </header>
  
      
     <Bur />
     <div className="mainTextsh">
 <img className="prod" src="https://via.placeholder.com/500"/>
     </div>

  </main>
            
  
        );
      }
    }

  export default Shop