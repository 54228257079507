

import './App.scss';
import partOne from './lgp1.png';
import partTwo from './lgp2.png';
import Mist from './mist.png';
import ReactDOM from 'react-dom';
import Vid from './may.mp4';
import Logo from './cplogo.png';
import Cart from './bag.png';
import * as THREE from "three";
import App from "./App"
import React, { Suspense } from 'react'
import { Canvas, useLoader } from 'react-three-fiber'
import img from './suns.jpg'

import ThreeScene from "./ThreeScene"
import ThreeSceneTw from "./ThreeSceneTw"



import Bur from './HamMenu.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  BrowserRouter
} from "react-router-dom";







class About extends React.Component {

 

  render() {
    return (
        

        
<main className="HomeTR">
   
   <div>
       <video src={Vid} autoPlay="true" loop="true" />
   </div>
   <header className="viewport-header">
 
 <div className="navBar">
   
     <img className="cpl" src={Logo}/>
     
     
 </div>
 
 


     </header>
<div className ="aback">
     <div className="mainTextA">
       <h1>A Propos de<span>Mist</span></h1>
<p className="textab">La fondatrice de mist orienté vers les produits naturels <br />
se découvre une passion pour les parfums et lui viens l'idée de crée <br />
  un pack de parfums qui garantie une odeur homogéne a longueur de journée  <br />
  de la téte au pied , ce concept unique vous garantie une senteur sublime<br /> 
  grace aux actions prodigieuse de nos trois produits<br />
   une utilisation du parfum de corp avant de s'habiller <br />
  étaler une noisette du parfum solide sur la nuque <br />
  et une petite touche du parfum de cheveux pour garantir une briance<br />
   et une senteur sans précédant<br />
    nos produit sont naturels sans alcool et notre fabrication est 100% local </p>
     </div>
     </div>
   <Bur />
  
  
        
    
</main>
        

    );
  }
  
}

  export default About