import logo from './logo.png';
import './App.scss';
import Loader from './Loader';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  BrowserRouter,
  Redirect
  
} from "react-router-dom";
import About from "./About";
import Home from "./Home";
import Shop from "./Shop";
import Contact from "./Contact";




function App() {
  return (
    

    <div className="App">
    <BrowserRouter>
    <Switch>
    <Route path="/home">
            <Home />
          </Route>
    
          <Route path="/about">
            <About />
          </Route>
          <Route path="/shop">
            <Shop />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
    <Redirect to="/home" />
</Route>
        </Switch>
        </BrowserRouter>
      
    </div>
   
   
  );
}

export default App;
